import React from "react"
import Layout from '../components/layout'
import SEO from '../components/seo'


export default function Shop() {
  return (
    <>
    <Layout>

      <SEO 
        title="Shop"
        description="Maybe the weirdest shopping guide you've ever come accross. Maybe... We don't know what you do in your personal time. Why's your browser history blank?"
      />

      <div className="page-heading-box">
        <h1>SHOP</h1>
      </div>
    </Layout>
    </>
  )
}